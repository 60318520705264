import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getApiClient from '@utils/getApiClient';
import { i18n } from '@lingui/core';
import { createCompiledCatalog } from './translations.compile';
import * as plural from 'make-plural/plurals';
import configService from '@services/config.service';
const client = getApiClient({ baseURL: configService.TahoeUrl });
const getIsoCode = ({ isoCode }, withUnderscore) => {
    const code = isoCode === 'ar-AE' ? 'ar-WW' : isoCode;
    return withUnderscore ? code.replace('-', '_') : code;
};
const getBrowserPreferredLanguage = (localeData) => {
    const isoCode = window.navigator.languages
        ? window.navigator.languages[0]
        : window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];
    const browserLanguage = localeData?.find((locale) => {
        return locale.isoCode === isoCode || locale.cultureLanguageCode === isoCode;
    });
    return browserLanguage || DEFAULT_CONTEXT.selectedLanguage;
};
const DEFAULT_SELECTED_LANGUAGE = {
    cultureDisplayName: 'English (United States)',
    cultureLanguageCode: 'en',
    cultureRegionCode: 'US',
    direction: 'LTR',
    isoCode: 'en-US',
    lcId: 'en_US',
    name: 'English',
    translatedName: 'English',
};
const DEFAULT_CONTEXT = {
    localeIsLoading: false,
    localeError: null,
    localeData: [DEFAULT_SELECTED_LANGUAGE],
    selectedLanguage: DEFAULT_SELECTED_LANGUAGE,
    setLanguage: () => null,
    translationsError: null,
    translationsIsLoading: false,
    translations: {},
};
const LanguageServiceContext = createContext(DEFAULT_CONTEXT);
export const useLanguageServiceContext = () => useContext(LanguageServiceContext);
const LanguageServiceProvider = ({ children }) => {
    const prevIsoCode = useRef(getIsoCode(DEFAULT_CONTEXT.selectedLanguage));
    const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_CONTEXT.selectedLanguage);
    const [translations, setTranslations] = useState(DEFAULT_CONTEXT.translations);
    const languageHasChanged = useMemo(() => {
        const newIsoCode = getIsoCode(selectedLanguage);
        const hasChanged = prevIsoCode.current !== newIsoCode;
        if (hasChanged) {
            prevIsoCode.current = newIsoCode;
        }
        return hasChanged;
    }, [selectedLanguage, prevIsoCode]);
    const { isLoading: localeIsLoading, error: localeError, data: localeData, } = useQuery(['localeData'], async () => {
        const { data = DEFAULT_CONTEXT.localeData } = await client.get('/res/locales.json');
        return data;
    }, {
        placeholderData: DEFAULT_CONTEXT.localeData,
        staleTime: Infinity,
        onError: (error) => {
            console.error('GET_LOCALE_ERROR', error);
        },
    });
    const { error: translationsError, isLoading: translationsIsLoading } = useQuery(['translationsData', selectedLanguage], async () => {
        const { data = DEFAULT_CONTEXT.localeData } = await client.get(`/actions/verbiageLookup/${getIsoCode(selectedLanguage, true)}`);
        return data;
    }, {
        enabled: !localeIsLoading,
        staleTime: languageHasChanged ? 0 : Infinity,
        onError: (error) => {
            console.error('GET_TRANSLATIONS_ERROR', error);
        },
        onSuccess: async (translations) => {
            // TODO: Do whatever is needed when translations are loaded
            const compiledTranslations = {};
            try {
                const language = getIsoCode(selectedLanguage);
                const { messages } = await import(`../../locales/${language}/messages`);
                const compiledTranslations = eval('(' + createCompiledCatalog(translations.data, { strict: true }) + ')');
                i18n.load(language, messages);
                i18n.loadLocaleData({
                    [language]: { plurals: plural[language.slice(0, 2).toLowerCase()] },
                });
                i18n.load(language, compiledTranslations);
                i18n.activate(language);
                setTranslations(compiledTranslations);
                // setMFEI18n({ ...selectedLanguage, translations: compiledTranslations });
            }
            catch (error) {
                console.error('SETMFEI18N_ERROR', error);
                setTranslations(compiledTranslations);
            }
        },
    });
    const setLanguage = (locale) => {
        setSelectedLanguage(locale);
    };
    useEffect(() => {
        setLanguage(getBrowserPreferredLanguage(localeData));
    }, [localeData]);
    const values = {
        localeIsLoading,
        localeError,
        localeData,
        selectedLanguage,
        setLanguage,
        translationsError,
        translationsIsLoading,
        translations,
    };
    return <LanguageServiceContext.Provider value={values}>{children}</LanguageServiceContext.Provider>;
};
export default LanguageServiceProvider;
