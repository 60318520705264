import React from 'react';
import styles from './MainNav.module.scss';
import getClassName from '@utils/getClassName';
import Link from '@core/Link';
import SessionStorageUtility from '@utility/sessionStorageUtility';
import { appNames } from 'common/constants/appNames';
import PermissionService from '@services/permission.service';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
const TemplateComponent = ({ className }) => {
    const { portalUrl } = SessionStorageUtility.getPortalConfiguration();
    const { canViewRisklineTravelData } = new PermissionService().permissions;
    const [rootClass, getChildClass] = getClassName({
        styles,
        rootClass: 'mainNav',
        className,
    });
    const { i18n } = useLingui();
    return (<nav className={rootClass}>
            <Link to={`/${portalUrl}/contact`} onPrimary>
                {t(i18n) `Profile`}
            </Link>
            <Link to={`/${portalUrl}/${appNames.TravelPortal}/travel`} onPrimary>
                {t(i18n) `Travel`}
            </Link>
            {canViewRisklineTravelData() && (<Link to={`/${portalUrl}/${appNames.TravelPortal}/location-intel`} onPrimary>
                    {t(i18n) `Location Intel`}
                </Link>)}
            <a rel="noopener noreferrer" href="https://worldtravelprotection.com/learning-guides/" target="_blank" className={getChildClass('link')}>
                {t(i18n) `Travel Awareness Training`}
            </a>
        </nav>);
};
export default TemplateComponent;
