import { Banner } from '@components/Banner/Banner';
import DeactivatedContainer from '@components/DeactivatedContainer/DeactivatedContainer';
import ErrorContainer from '@components/ErrorContainer/ErrorContainer';
import Footer from '@components/Footer/Footer';
import Policies from '@components/Footer/Policies';
import Header from '@components/Header/Header';
import CustomHelpText from '@components/Help/CustomHelpText';
import SessionTimeoutModal from '@components/SessionTimeoutModal/SessionTimeoutModal';
import { SignInCallback } from '@components/SignInCallback/SignInCallback';
import { SilentAccessTokenRenewCallback } from '@components/SilentAccessTokenRenewCallback/SilentAccessTokenRenewCallback';
import { useLanguageServiceContext } from '@context/LanguageServiceProvider/LanguageServiceProvider';
import { useThemeContext } from '@context/ThemeProvider';
import LoadingOverlay from '@corecomponents/Loading/LoadingOverlay';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { PrivateRoute } from '@routes/PrivateRoute';
import { identityRoutes } from '@routes/identityRoutes';
import configService from '@services/config.service';
import { identityManager } from '@services/identityManager';
import namespaceService from '@services/namespace.service';
import PermissionService from '@services/permission.service';
import { isDevelopment } from '@utils/envUtilities';
import getClassName from '@utils/getClassName';
import MicroFrontend from 'MicroFrontend/MicroFrontend';
import { appNames } from 'common/constants/appNames';
import MainNav from 'layout/MainNav';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import LocalStorageUtility from './common/utility/localStorageUtility';
import SessionStorageUtility from './common/utility/sessionStorageUtility';
import { updatedPush } from './common/utility/updatedPush';
import React, { useEffect } from 'react';
import { updateDocumentMetadata } from '@utils/updateDocumentMetadata';
import travelAssistFaviconPath from 'assets/images/travel_assist_favicon.ico';
import onsolveFaviconPath from 'assets/images/favicon.ico';
import RegistrationSuccessful from '@components/RegistrationSuccessful/RegistrationSuccessful';
const { protocol } = window.location;
const { pathname } = window.location;
if (pathname === '/') {
    window.location.pathname = '/error=portal name required';
}
const externalDomainName = configService.ExternalDomainName;
const externalHost = namespaceService.isLocalhost()
    ? `${protocol}//${externalDomainName}`
    : `${protocol}//accountportal.${externalDomainName}`;
const BuildInformation = () => {
    const BUILD_VERSION_KEY = 'build_version';
    const buildVersion = VERSION;
    const currentBuildVersion = LocalStorageUtility.getData(BUILD_VERSION_KEY);
    if (currentBuildVersion !== buildVersion) {
        LocalStorageUtility.setData(BUILD_VERSION_KEY, buildVersion);
        console.info({ currentBuildVersion, buildVersion });
    }
};
const TravelPortal = ({ theme }) => {
    const mfeHistory = useHistory();
    const { push } = mfeHistory;
    const { i18n } = useLingui();
    const { TravelPortal: travelPortal } = appNames;
    const portalConfiguration = SessionStorageUtility.getPortalConfiguration();
    const { translations } = useLanguageServiceContext();
    return (<MicroFrontend history={{
            ...mfeHistory,
            push: updatedPush(push, travelPortal, portalConfiguration?.portalUrl),
        }} host={externalHost} name={travelPortal} localization={{
            languageCode: i18n.locale,
            messages: translations, // Actually provide the messages from the language endpoint
        }} portalConfiguration={portalConfiguration} theme={theme}/>);
};
const ProfileManagement = ({ theme }) => {
    const mfeHistory = useHistory();
    const { i18n } = useLingui();
    const { ProfileManagement: profileManagement } = appNames;
    const portalConfiguration = SessionStorageUtility.getPortalConfiguration();
    const { translations } = useLanguageServiceContext();
    return (<MicroFrontend history={mfeHistory} host={externalHost} name={profileManagement} localization={{
            languageCode: i18n.locale,
            messages: translations, // Actually provide the messages from the language endpoint
        }} portalConfiguration={portalConfiguration} theme={theme}/>);
};
const App = () => {
    const isPrint = useLocation().pathname.toLowerCase().includes('/print/');
    window.mfeHistory = useHistory();
    const { themeTitle, themeColor, themeLogo, isLoading } = useThemeContext();
    const { isTravelEnabled = false, portalUrl } = SessionStorageUtility.getPortalConfiguration() ?? {};
    const { canViewRITravelManagement, canViewWTPCustomer } = new PermissionService().permissions;
    const canViewTravelPortal = isTravelEnabled && canViewRITravelManagement();
    const [rootClass, getChildClass] = getClassName({
        styles,
        rootClass: 'page',
        modifiers: {
            [themeColor]: !!themeColor,
        },
    });
    useEffect(() => {
        const isWTPCustomer = canViewWTPCustomer();
        const title = isWTPCustomer ? 'Travel Assist' : 'OnSolve';
        const faviconPath = isWTPCustomer ? travelAssistFaviconPath : onsolveFaviconPath;
        updateDocumentMetadata(title, faviconPath);
    }, []);
    //Defaulting to RI takes precidence over everything, if they have RI, default there.
    //If they don't have RI, Control Center takes precedence over OnSolve.UI (criticalcomms)
    return (<div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {!isDevelopment() && <SessionTimeoutModal />}
            {!isLoading && !isPrint && <Header />}
            <div style={{ flex: '1 1 0' }}>
                <div className={`d-flex flex-column h-100 ${rootClass}`}>
                    {!isLoading && !isPrint && canViewTravelPortal && <MainNav className={getChildClass('nav')}/>}
                    {!isPrint && <Banner />}
                    {isLoading && <LoadingOverlay />}

                    <Switch>
                        <Route path={identityRoutes.signinCallback}>
                            <SignInCallback />
                        </Route>

                        <Route path="/error">
                            {!isLoading && (<ErrorContainer goHome={() => identityManager.handleUserCleanData()} title={t `Error`} description={t `An error has occurred. Please contact your administrator.`}/>)}
                        </Route>
                        <Route path="/error=portal name required">
                            {!isLoading && (<ErrorContainer goHome={() => identityManager.handleUserCleanData()} title={t `Error`} description={t `Please use your organization's specific workspace URL to reach the appropriate account portal.`} showHomeButton={false}/>)}
                        </Route>

                        <Route path={identityRoutes.accessTokenRenewCallback}>
                            <SilentAccessTokenRenewCallback />
                        </Route>

                        <Route path="/deactivated">
                            {!isLoading && (<DeactivatedContainer goHome={() => identityManager.signinRedirect()} title={t `Account Deactivated`} description={t `Your account has been successfully deactivated.`}/>)}
                        </Route>
                        <Route path="/:portalUrl/policies/:selectedPolicy">
                            <Policies />
                        </Route>
                        <Route path="/:portalUrl/policies">
                            <Policies />
                        </Route>
                        <Route path="/:portalUrl/help">
                            <CustomHelpText />
                        </Route>
                        <PrivateRoute exact path="/:portalUrl/contact">
                            {!isLoading && (<ProfileManagement theme={{ name: themeColor, logo: themeLogo, title: themeTitle }}/>)}
                        </PrivateRoute>

                        <PrivateRoute path="/:portalUrl/travelportal">
                            {!isLoading && canViewTravelPortal && (<TravelPortal theme={{ name: themeColor, logo: themeLogo, title: themeTitle }}/>)}
                        </PrivateRoute>

                        <Route path="/denied">
                            {!isLoading && (<DeactivatedContainer goHome={() => identityManager.handleUserCleanData()} title={t `Access is denied`} description={t `You are not allowed to access this Account Portal. Please contact your administrator.`}/>)}
                        </Route>
                        <Route path="/RegistrationSuccessful">
                            {!isLoading && (<RegistrationSuccessful isLoading={isLoading} portalUrl={portalUrl + '/contact'}></RegistrationSuccessful>)}
                        </Route>
                        <Redirect from="/:portalUrl" to="/:portalUrl/contact"/>
                    </Switch>
                </div>
            </div>

            {!isLoading && !isPrint && <Footer />}
        </div>);
};
BuildInformation();
export default App;
